import moment from "moment";

export const useUtils = () => {
  return {
    fileToBase64: (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result + "");
        reader.onerror = (error) => reject(error);
      });
    },
    base64ToFile: (base64: string): Promise<File> => {
      return new Promise((resolve) => {
        const byteNumbers = new Uint8Array(base64.length);
        for (let i = 0; i < base64.length; i++) {
          byteNumbers[i] = base64.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: "image/png" });

        // Tạo đối tượng File từ Blob
        const reader = new FileReader();
        reader.onload = (event) => {
          const file = new File([blob], "image.png", { type: "image/png" });
          return resolve(file);
        };
        reader.readAsDataURL(blob);
      });
    },
    readBlobToBase64: (blob: any): Promise<string> => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve, reject) => {
        reader.onloadend = function () {
          try {
            var base64data: string = reader.result + "";
            return resolve(base64data);
          } catch (error) {
            return reject(error);
          }
        };
      });
    },
    copyText: (text: string) => {
      var inputDump = document.createElement("input");
      document.body.appendChild(inputDump);
      inputDump.value = text;
      inputDump.select();
      document.execCommand("copy");
      document.body.removeChild(inputDump);
    },
    redirectUrl(url: string, target: string = "_blank") {
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", target);
      a.click();
      a.remove();
    },
    convertBytes(bytes: number) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) {
        return "0 Byte";
      }
      //@ts-ignore
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      //@ts-ignore
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    isoDateString(time: string) {
      if (time)
        return moment(time)
          .toISOString()
          .replaceAll("-", "")
          .replaceAll(":", "");
      return "";
    },
    objectToQuery(ob: Object) {
      var keys = Object.keys(ob);
      var data: string[] = [];
      keys.forEach((item) => {
        //@ts-ignore
        data.push(item + "=" + ob[item]);
      });
      return data.join("&");
    },
  };
};
